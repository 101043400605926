
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "LandingDiscoverMentors",
  computed: {
    ...mapGetters({
      item: "helper/getLandingPage",
      homepageBgPrimary: "helper/getHomePageBgPrimaryStyle"
    })
  },

  mounted() {
    this.$store.dispatch("helper/GET_LANDING_PAGE");
  }
});
